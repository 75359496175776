import { Link as ReactLink } from "react-router-dom";
import {
  Box,
  VStack,
  Text,
  Image,
  Button,
  Link,
  Wrap,
  WrapItem,
  HStack,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";

const BlogPost = ({ blog }) => {
  if (!blog || !blog.content) {
    return <div>No content available</div>; // Handle the absence of data
  }

  return (
    <Box m={8}>
      <Box mb={8}>
        <Text
          fontSize={["xl", "2xl", "3xl", "5xl"]}
          color="green.300"
          style={{ fontFamily: "Lobster" }}
          textAlign="center"
        >
          {blog.title}
        </Text>
      </Box>
      {blog.content.map((item, index) => {
        if (item.type === "paragraph") {
          return (
            <Box key={index}>
              <Text>{item.text}</Text>
            </Box>
          );
        } else if (item.type === "heading") {
          return (
            <Box mt={5}>
              <Text as="b" key={index}>
                {item.text}
              </Text>
            </Box>
          ); // You can use h1, h2, etc. based on your structure
        } else if (item.type === "image") {
          return (
            <img
              key={index}
              src={item.src}
              alt={item.alt}
              style={{ width: "100%", height: "auto" }}
            />
          );
        } else if (item.type === "list") {
          return (
            <List key={index} spacing={3} mt={3}>
              {item.items.map((_listItem, listIndex) => (
                <ListItem key={listIndex}>
                  <Text as="i">{_listItem.itemTitle}</Text>
                  <Text>{_listItem.itemText}</Text>
                </ListItem>
              ))}
            </List>
          );
        } else if (item.type === "product") {
          return (
            <Box key={index} mt="30px" maxW="sm" mx="auto">
              <VStack justifyContent="center" alignItems="center">
                <Link href={item.link} isExternal>
                  <img
                    src={item.image.src}
                    alt={item.image.alt}
                    style={{ width: "218px", height: "auto" }}
                  />
                </Link>
                <Text textAlign="center">{item.description}</Text>
              </VStack>
            </Box>
          );
        }
        return null; // Fallback in case of unexpected type
      })}
    </Box>
  );
};

export default BlogPost;
