import React from "react";
import { VStack, HStack, Container, Icon, Text, Link } from "@chakra-ui/react";
import {
  FaTwitter,
  FaYoutube,
  FaPinterest,
  FaFacebook,
  FaInstagramSquare,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function Footer() {
  return (
    <VStack mt="18px">
      <HStack>
        <Link href="https://www.pinterest.com/MindFlexZone/" isExternal>
          <Icon as={FaPinterest} boxSize={8} color="green.300" />
        </Link>
        {/* <Link href="https://www.instagram.com/natureharmonyboutique" isExternal>
          <Icon as={FaInstagramSquare} boxSize={8} color="green.300" />
        </Link> */}
      </HStack>
      <Text as="i" color="teal.500" fontSize="xs">
        Copyright &copy; 2024 mindflexzone.com
      </Text>
    </VStack>
  );
}

export default Footer;
