import { ChakraProvider, Box, HStack, theme, VStack } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import ScrollToTop from "./components/views/ScrollToTop";
import Footer from "./components/views/Footer";
import Layout from "./components/views/Layout";
import Home from "./pages/Home.js";
import NoPage from "./pages/misc/NoPage.js";
// import Blogs from "./pages/blogs/Blogs.js";
// import FAQ from "./pages/misc/FAQ.js";
import About from "./pages/misc/About.js";
import "./App.css";
import NavBreadCrumb from "./components/views/NavBreadCrumb.js";
// import ShippingAndReturns from "./pages/misc/ShippingAndReturns.js";
// import StorePolicy from "./pages/misc/StorePolicy.js";
// import ImageNature from "./components/views/ImageNature.js";
// import ShopApparel from "./pages/shop/ShopApparel.js";
// import ShopWallArt from "./pages/shop/ShopWallArts.js";
import Blog from "./pages/blogs/Blog.js";

function AppLocation() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isBlogPage = location.pathname.startsWith("/blogs/");

  return (
    <ChakraProvider theme={theme}>
      {/* <RouteComponent justifySelf="flex-end"
      style={{
        position: 'absolute',
        top: '0%',
        left: '0%',
      }}/> */}

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="/boutique" element={<Boutique />} /> */}
          {/* <Route path="/style/apparel" element={<ShopApparel />} />
          <Route path="/style/wallart" element={<ShopWallArt />} /> */}

          {/* <Route path="/blogs" element={<Blogs />} /> */}
          <Route path="/blogs/:blogId" element={<Blog />} />

          {/* <Route path="/faq" element={<FAQ />} /> */}
          <Route path="/about" element={<About />} />
          {/* <Route path="/shipping" element={<ShippingAndReturns />} /> */}
          {/* <Route path="/policy" element={<StorePolicy />} /> */}

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>

      <Box
        style={{
          position: "absolute",
          top: "0%",
          right: "0%",
        }}
      >
        <HStack spacing={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
        </HStack>
      </Box>

      <ScrollToTop />
      <VStack>
        {/* {!isGameRoute && (
          <Box mb="8px" mt="58px">
            <NavBreadCrumb />
          </Box>
        )}
        {!isGameRoute && (
          <Box mb="8px">
            <CreatorInfo />
          </Box>
        )} */}

        <Box mb="8px" mt="58px">
          <NavBreadCrumb />
        </Box>

        <Box>
          <Footer />
        </Box>
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </ChakraProvider>
  );
}

function App() {
  return (
    <Router>
      <AppLocation />
    </Router>
  );
}

export default App;
