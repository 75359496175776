import { useParams } from "react-router-dom";
import { Box, VStack, Text, Container } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AllBlogs } from "../../components/views/blogs/articles/AllBlogs"; // Ensure this matches your export
import { DefaultBlog } from "./DefaultBlog";
import HomeIcon from "../../components/views/HomeIcon";

import BlogPosts from "../../components/views/blogs/articles/BlogPosts";
import BlogPost from "../../components/views/blogs/articles/BlogPost";

const Blog = () => {
  const { blogId } = useParams();
  console.log("blogId:", blogId);
  const [blog, setBlog] = useState(DefaultBlog);

  useEffect(() => {
    // Convert blogId to an integer, as useParams returns a string
    const id = Number(blogId);
    console.log("blog:", BlogPosts[id]);

    setBlog(BlogPosts[id]);
  }, [blogId]); // The dependency array should include blogId

  // Check if blog is still null before rendering
  if (!blog) {
    return <Text>Loading...</Text>; // Optional: Display a loading message while fetching the blog
  }

  return (
    <>
      <VStack>
        <HomeIcon />
      </VStack>
      <VStack
        spacing={3}
        p={6}
        ml={["20px", "30px", "108px"]}
        mr={["20px", "30px", "108px"]}
      >
        <BlogPost blog={blog} />
        {/* {" "}
        <Box>
          <Text fontSize="xl" fontWeight="bold" textAlign="center" mb={6}>
            {blog.title}
          </Text>
          {blog.paragraphs.map((para, key) => (
            <Box key={key} mb={4}>
              <Text fontSize="lg" fontWeight="semibold">
                {para.subtitle}
              </Text>
              <Text>{para.paragraph}</Text>
            </Box>
          ))}
          <Text as="b">{blog.conclusion.take_home_message}</Text>
          <Box mt={4}>
            <Text>{blog.conclusion.paragraph}</Text>
          </Box>
        </Box> */}
      </VStack>
    </>
  );
};

export default Blog;
