import {
  Box,
  VStack,
  Text
} from "@chakra-ui/react";
import HomeBg from "../resources/images/brain.png";
import RenderBlogs from "../components/views/blogs/RenderBlogs";

function Home() {
  return (
    <>
      <VStack spacing={6}>
        <Box m="18px">
          <VStack spacing={8}>
            <Text
              fontSize={["xl", "2xl", "3xl", "8xl"]}
              color='green.300'
              style={{ fontFamily: "Lobster" }}
            >
              Mind Flex Zone
            </Text>

            <img
              src={HomeBg}
              alt="Brain"
              style={{ width: "400px", height: "auto" }}
            />

            <Text fontSize={["md", "lg", "xl"]} mt={3}>
            Positive Thinking → Transform Your Life → Achieve Happiness & Success.
            </Text>
          </VStack>
        </Box>
        <RenderBlogs />
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </>
  );
}

export default Home;
