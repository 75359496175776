import {
  Box,
  VStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Link,
  Icon,
} from "@chakra-ui/react";
import { AiTwotoneMail } from "react-icons/ai";
import HomeIcon from "../../components/views/HomeIcon";
import AboutImg from "../../resources/images/About.png";
import BrainImage from "../../resources/images/tree1.png";
import MindFlexImage from "../../resources/images/leaves.png";

// import GreenLeaf from "../../resources/images/zen-elements/18.png";
function About() {
  const RenderContact = () => {
    return (
      <Box
        maxW={["xs", "sm", "md"]}
        p={6}
        fontFamily="Helvetica Neue"
        textAlign="left"
      >
        <VStack>
          <Text fontSize={["lg", "xl", "2xl"]}>
            Have a question, suggestion, or just want to say hello? Drop us a
            line—we'd love to hear from you!
          </Text>
          <Link href="mailto:support@mindflexzone.com">
            <Icon as={AiTwotoneMail} boxSize="88px" color="green.300" />
          </Link>
          <Text fontSize={["lg", "xl"]}>support@mindflexzone.com</Text>
        </VStack>
      </Box>
    );
  };
  const AboutSection = () => {
    return (
      <Box p={6} border="none" borderWidth="0" maxW={["md", "lg", "xl", "2xl"]}>
        <Box
          fontSize={["md", "lg", "xl", "2xl"]}
          fontFamily="Helvetica Neue"
          textAlign="left"
        >
          <VStack spacing={6}>
            <Text>
              Welcome to Mind Flex Zone! At Mind Flex Zone, we believe in the
              incredible power of your mind to shape your reality, achieve personal
              growth, and unlock happiness. Our platform is dedicated to empowering
              you with tools, insights, and inspiration to cultivate a positive
              mindset and embrace mental transformation.
            </Text>
            <Box boxSize="258px">
              <Image src={BrainImage} alt="Brain with positive symbols" />
            </Box>
  
            <Text>
              Through a blend of holistic views, neuroscience insights, and practical advice, we aim to help you
              strengthen your mental resilience and create lasting change. Whether
              you’re looking to reinforce positive thinking habits, set and achieve
              life goals, or simply find balance in a chaotic world, we’re here to
              guide you every step of the way.
            </Text>
            <Box boxSize="218px">
              <Image src={MindFlexImage} alt="Mind Flex Transformation" />
            </Box>
  
            <Text>
              At Mind Flex Zone, we invite you to explore our resources, including
              motivational articles, practical guides, and helpful tools for
              personal development. Join our growing community of like-minded
              individuals on a journey of mental transformation, and together, we
              can tap into the limitless potential of our minds to thrive, succeed,
              and live happier lives.
            </Text>
          </VStack>
        </Box>
      </Box>
    );
  };
  
  
  return (
    <>
      <VStack spacing={6}>
        <HomeIcon />
        <img src={AboutImg} style={{ width: "318px", height: "auto" }} />
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          size="lg"
          align="center"
        >
          <TabList>
            <Tab>
              <Text fontSize={["xl", "2xl"]}>About</Text>
            </Tab>
            <Tab>
              <Text fontSize={["xl", "2xl"]}>Contact</Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AboutSection />
            </TabPanel>
            <TabPanel>
              <RenderContact />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </>
  );
}

export default About;
